<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Work Blocks</h3>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-select
            label="Status"
            :items="statuses"
            v-model="filter.status"
            outlined
            dense
            hide-details
            style="maxwidth: 200px"
          ></v-select>
          <v-btn
            depressed
            color="accent"
            class="ml-2"
            @click="$refs.workChunkForm.openForm()"
          >
            <v-icon left>mdi-plus</v-icon>Add Work Block
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeadersWorkload"
      :items="workChunks"
      no-data-text="No work block found"
    >
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: 'module-workload-individual',
            params: { chunkId: item.id },
          }"
        >
          {{ item.name }}</router-link
        >
      </template>

      <template v-slot:item.groups="{ item }">
        <div v-for="(group, index) in item.groups" v-bind:key="group.id">
          <span> {{ group.name }}</span
          ><span v-if="index + 1 < item.groups.length">, </span>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              class="mr-2"
              @click="$refs.workChunkForm.openForm(item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="red lighten-4 red--text"
              v-on="on"
              @click="
                archiveWorkloadDialog = true;
                archiveWorkloadData = item;
              "
            >
              <v-icon x-small>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Archive</span>
        </v-tooltip>-->
      </template>
    </v-data-table>
    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
      :userId="user.id"
      :customerId="customerId"
    />
  </div>
</template>

<script>
import WorkChunkForm from "@/modules/workload/views/chunks/components/WorkChunkForm";

export default {
  components: {
    WorkChunkForm,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      filter: {
        status: "Open",
      },
      tableHeadersWorkload: [
        { text: "Title", value: "name" },
        { text: "Type", value: "type" },
        { text: "Value", value: "value" },
        { text: "Status", value: "status" },
        { text: "Rate", value: "rate" },
        // { text: "Hours Logged", value: "total_time_logged.text" },
        { text: "Workgroups", value: "groups" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      statuses: ["Open", "Closed", "Deleted", "Completed"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
      archiveDomainDialog: false,
      archiveDomainLoading: false,
      archiveDomainData: null,
    };
  },

  computed: {
    workChunks() {
      return this.$store.state.workload["work_chunks"].filter(
        (c) => c.status === this.filter.status
      );
    },
    groups: function () {
      return this.$store.state.workload["groups"];
    },
    user() {
      return this.$store.state.workload["user"];
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
    customerId() {
      return parseInt(this.$route.params.customerId);
    },
  },
};
</script>
